import React from "react";
import Header from "../../components/panel/Header";
import PanelLayout from "../../components/panel/Layout";

const Profile = () => {
  return (
    <PanelLayout>
      <Header title="Profile" />
      <main className="leave-apply-container">
        <div className="main-content">
          <div className="form-section">
            <h3>Basic Details</h3>
            {/* <h4>Reason</h4> */}
            <div className="date-inputs">
              {/* <label htmlFor="empId">Employee ID</label> */}

              <input type="date" className="date-input" placeholder="From date" />
              <input type="date" className="date-input" placeholder="To date" />
            </div>
            <div className="date-inputs">
              <input type="date" className="date-input" placeholder="From date" />
              <input type="date" className="date-input" placeholder="To date" />
            </div>
            <button className="btn btn-send">UPDATE</button>
          </div>
        </div>
        <div className="main-content">
          <div className="form-section">
            <h3>Bank Details</h3>
            <h4>Reason</h4>
            <div className="date-inputs">
              <input type="date" className="date-input" placeholder="From date" />
              <input type="date" className="date-input" placeholder="To date" />
            </div>
            <div className="date-inputs">
              <input type="date" className="date-input" placeholder="From date" />
              <input type="date" className="date-input" placeholder="To date" />
            </div>
            <h3>Reason</h3>
            <textarea
              className="reason-textarea"
              placeholder="Type here the reason for the leave"
            ></textarea>
            <button className="btn btn-send">UPDATE</button>
          </div>
        </div>
        <div className="main-content">
          <div className="form-section">
            <h3>Other Details</h3>
            <h4>Reason</h4>
            <div className="date-inputs">
              <input type="date" className="date-input" placeholder="From date" />
              <input type="date" className="date-input" placeholder="To date" />
            </div>
            <div className="date-inputs">
              <input type="date" className="date-input" placeholder="From date" />
              <input type="date" className="date-input" placeholder="To date" />
            </div>
            <h3>Reason</h3>
            <textarea
              className="reason-textarea"
              placeholder="Type here the reason for the leave"
            ></textarea>
            <button className="btn btn-send">UPDATE</button>
          </div>
        </div>
      </main>
    </PanelLayout>
  );
};

export default Profile;
