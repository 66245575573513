import { createAsyncThunk } from "@reduxjs/toolkit";
import { User } from "./types";

export const userApi = {
  fetchUserById: async (id: string): Promise<User> => {
    const response = await fetch(`/api/users/${id}`);
    if (!response.ok) throw new Error("Failed to fetch user");
    return response.json();
  },
};

export const fetchUserById = createAsyncThunk(
  "user/fetchUserById",
  async (id: string, { rejectWithValue }) => {
    try {
      return await userApi.fetchUserById(id);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);
