import {
  FaBuildingColumns,
  FaCalendarMinus,
  FaFingerprint,
  FaMoneyBills,
  FaUmbrellaBeach,
} from "react-icons/fa6";
import { NavLink } from "react-router-dom";

const SideMenu = () => {
  return (
    <aside className="sidebar">
      <div className="brand">
        <h1 className="brand-title">
          <FaBuildingColumns size="24px" />
          <span>ESS</span>
        </h1>
      </div>
      <ul className="menu">
        <li className="menu-item">
          <NavLink
            to="/panel/home"
            className={({ isActive, isPending }) =>
              isPending ? "pending" : isActive ? "active" : ""
            }
          >
            <FaBuildingColumns size="16px" />
            Home
          </NavLink>
        </li>
        <li className="menu-item">
          <NavLink
            to="/panel/punch"
            className={({ isActive, isPending }) =>
              isPending ? "pending" : isActive ? "active" : ""
            }
          >
            <FaFingerprint size="16px" />
            Punch
          </NavLink>
        </li>
        <li className="menu-item">
          <NavLink
            to="/panel/attendance"
            className={({ isActive, isPending }) =>
              isPending ? "pending" : isActive ? "active" : ""
            }
          >
            <FaCalendarMinus size="16px" />
            Attendance
          </NavLink>
        </li>
        <li className="menu-item">
          <NavLink
            to="/panel/salary"
            className={({ isActive, isPending }) =>
              isPending ? "pending" : isActive ? "active" : ""
            }
          >
            <FaMoneyBills size="16px" />
            Salary
          </NavLink>
        </li>
        <li className="menu-item">
          <NavLink
            to="/panel/leave"
            className={({ isActive, isPending }) =>
              isPending ? "pending" : isActive ? "active" : ""
            }
          >
            <FaUmbrellaBeach size="16px" />
            Leave
          </NavLink>
        </li>
      </ul>
    </aside>
  );
};

export default SideMenu;
