import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { userLogin } from "./authApi";
import { Auth, AuthState } from "./types";

const initialState: AuthState = {
  auth: {},
  loading: false,
  error: null,
};

const authSlice = createSlice({
  name: "auth-slice",
  initialState,
  reducers: {
    logout: (state, action: PayloadAction<Auth>) => {
      state.auth = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(userLogin.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(userLogin.fulfilled, (state, action: any) => {
        state.loading = false;
        console.log("action :>> ", action);
        state.auth = {
          accessToken: action.payload?.data?.accessToken,
          refreshToken: action.payload?.data?.refreshToken,
        };
      })
      .addCase(userLogin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export const { logout, setLoading, setError } = authSlice.actions;
export default authSlice.reducer;
