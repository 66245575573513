import React from "react";
import { Link, useNavigate } from "react-router-dom";

const ProfilePopup = () => {
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.clear();
    navigate("/auth/login");
  };
  return (
    <div className="popup-menu">
      <div className="popup-content">
        <div className="user-info">
          <p className="user-name">Syed Hasnain Mehadi</p>
          <p className="user-email">syed.mehadi@gmail.com</p>
        </div>
        <Link to="/panel/profile" className="menu-item">
          Your Profile
        </Link>
        <a href="#settings" className="menu-item">
          Settings
        </a>
        <a href="#activity" className="menu-item">
          Activity Log
        </a>

        <div className="menu-divider"></div>
        <p className="menu-item logout" onClick={handleLogout}>
          Sign out
        </p>
      </div>
    </div>
  );
};

export default ProfilePopup;
